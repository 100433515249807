@use '@angular/material' as mat;
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";
@import '@angular/material/theming';
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';


.ace_overflow{
  color: #5D90CD !Important; // ace error syntax
}

button:focus {
    outline: none;
    border: 0;
 }

.hidden_dialog {
  display: none;
}

.company_name_global {
  color: #1d80d4;
}

.cdk-overlay-pane {
  max-width: 90vw !important;
}
/* @media only screen and (max-width: 1441px) {
  .cdk-overlay-pane {
    max-width: 90vw !important;
  }
} */

.update-policy-from{
  .mat-card{
    box-shadow: none !important;
    .footer{
      place-content : center !important;
    }
  }
}

$mat-myapp-primary: (
  50 : #fdfcfd,
  100 : #f9f9f9,
  200 : #f6f5f6,
  300 : #f2f0f2,
  400 : #efedef,
  500 : #eceaec,
  600 : #eae7ea,
  700 : #e7e4e7,
  800 : #e4e1e4,
  900 : #dfdbdf,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #000000,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$mat-myapp-accent: (
  50 : #e7eff6,
  100 : #c2d7e9,
  200 : #99bddb,
  300 : #70a2cd,
  400 : #528ec2,
  500 : #337ab7,
  600 : #2e72b0,
  700 : #2767a7,
  800 : #205d9f,
  900 : #144a90,
  A100 : #c4dcff,
  A200 : #91bdff,
  A400 : #5e9eff,
  A700 : #458fff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$primary: mat.define-palette($mat-myapp-primary);
$accent: mat.define-palette($mat-myapp-accent);

$theme: mat.define-light-theme($primary, $accent);

@include mat.all-component-themes($theme);


